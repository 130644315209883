import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Card } from 'components/common';
import { Wrapper, Grid, Item, Content, Languages, Language } from './styles';

export const Projects = () => {
  const { theme } = useContext(ThemeContext);
  const edges = [
    {
      node: {
        id: 'R_kgDOKoTOBg',
        name: 'dapp-scaffold',
        url: 'https://github.com/ankitboghra/dapp-scaffold',
        homepageUrl: 'https://scaffoldeth.io',
        description: 'Open source forkable Ethereum dev stack',
        primaryLanguage: {
          id: 'MDg6TGFuZ3VhZ2UyODc=',
          color: '#3178c6',
          name: 'TypeScript',
        },
        languages: {
          edges: [
            {
              node: {
                name: 'Shell',
                color: '#89e051',
                id: 'MDg6TGFuZ3VhZ2UxMzk=',
              },
            },
            {
              node: {
                name: 'CSS',
                color: '#563d7c',
                id: 'MDg6TGFuZ3VhZ2UzMDg=',
              },
            },
            {
              node: {
                name: 'Solidity',
                color: '#AA6746',
                id: 'MDg6TGFuZ3VhZ2U2NjU=',
              },
            },
          ],
        },
      },
    },

    {
      node: {
        id: 'R_kgDOIjHcPA',
        name: 'vpay',
        url: 'https://github.com/ankitboghra/vpay',
        homepageUrl: null,
        description: null,
        primaryLanguage: {
          id: 'MDg6TGFuZ3VhZ2UxNDA=',
          color: '#f1e05a',
          name: 'JavaScript',
        },
        languages: {
          edges: [
            {
              node: {
                name: 'JavaScript',
                color: '#f1e05a',
                id: 'MDg6TGFuZ3VhZ2UxNDA=',
              },
            },
          ],
        },
      },
    },

    {
      node: {
        id: 'R_kgDOJbGheg',
        name: 'multi-chain-wallet',
        url: 'https://github.com/maticnetwork/multi-chain-wallet',
        homepageUrl: 'https://portal.polygon.technology/',
        description: 'Polygon Portal',
        primaryLanguage: {
          id: 'MDg6TGFuZ3VhZ2UyODc=',
          color: '#3178c6',
          name: 'TypeScript',
        },
        languages: {
          edges: [
            {
              node: {
                name: 'Shell',
                color: '#89e051',
                id: 'MDg6TGFuZ3VhZ2UxMzk=',
              },
            },
            {
              node: {
                name: 'Dockerfile',
                color: '#384d54',
                id: 'MDg6TGFuZ3VhZ2U1MzU=',
              },
            },
            {
              node: {
                name: 'JavaScript',
                color: '#f1e05a',
                id: 'MDg6TGFuZ3VhZ2UxNDA=',
              },
            },
          ],
        },
      },
    },

    {
      node: {
        id: 'R_kgDOGscHRQ',
        name: 'staking-web',
        url: 'https://github.com/maticnetwork/staking-web',
        homepageUrl: '',
        description: 'UI for Staking application',
        primaryLanguage: {
          id: 'MDg6TGFuZ3VhZ2U0ODM=',
          color: '#41b883',
          name: 'Vue',
        },
        languages: {
          edges: [
            {
              node: {
                name: 'Dockerfile',
                color: '#384d54',
                id: 'MDg6TGFuZ3VhZ2U1MzU=',
              },
            },
            {
              node: {
                name: 'CSS',
                color: '#563d7c',
                id: 'MDg6TGFuZ3VhZ2UzMDg=',
              },
            },
            {
              node: {
                name: 'HTML',
                color: '#e34c26',
                id: 'MDg6TGFuZ3VhZ2U0MTc=',
              },
            },
          ],
        },
      },
    },

    {
      node: {
        id: 'MDEwOlJlcG9zaXRvcnkzOTIyMTI4MDY=',
        name: 'matic.js',
        url: 'https://github.com/ankitboghra/matic.js',
        homepageUrl: '',
        description: 'Javascript developer library to interact with Matic Network',
        primaryLanguage: {
          id: 'MDg6TGFuZ3VhZ2UyODc=',
          color: '#3178c6',
          name: 'TypeScript',
        },
        languages: {
          edges: [
            {
              node: {
                name: 'Shell',
                color: '#89e051',
                id: 'MDg6TGFuZ3VhZ2UxMzk=',
              },
            },
            {
              node: {
                name: 'JavaScript',
                color: '#f1e05a',
                id: 'MDg6TGFuZ3VhZ2UxNDA=',
              },
            },
            {
              node: {
                name: 'TypeScript',
                color: '#3178c6',
                id: 'MDg6TGFuZ3VhZ2UyODc=',
              },
            },
          ],
        },
      },
    },

    {
      node: {
        id: 'R_kgDOJNK-Kw',
        name: 'zkThon-2023',
        url: 'https://github.com/ankitboghra/zkThon-2023',
        homepageUrl: null,
        description: 'Challenges for zkThon',
        primaryLanguage: null,
        languages: {
          edges: [],
        },
      },
    },

    {
      node: {
        id: 'R_kgDOGnz6eA',
        name: 'openapi-watchgod-merger',
        url: 'https://github.com/ankitboghra/openapi-watchgod-merger',
        homepageUrl: '',
        description:
          'Merge OpenAPI and Watchgod responses to track latest status of a deposit/withdraw transaction on Ethereum Chain and Polygon Chain.',
        primaryLanguage: {
          id: 'MDg6TGFuZ3VhZ2UxNDA=',
          color: '#f1e05a',
          name: 'JavaScript',
        },
        languages: {
          edges: [
            {
              node: {
                name: 'JavaScript',
                color: '#f1e05a',
                id: 'MDg6TGFuZ3VhZ2UxNDA=',
              },
            },
          ],
        },
      },
    },

    {
      node: {
        id: 'MDEwOlJlcG9zaXRvcnkzMDE3MTc5OTk=',
        name: 'matic-design-system',
        url: 'https://github.com/maticnetwork/matic-design-system',
        homepageUrl: 'https://matic-design-system.netlify.app',
        description: 'Design System for Matic products',
        primaryLanguage: {
          id: 'MDg6TGFuZ3VhZ2U0ODM=',
          color: '#41b883',
          name: 'Vue',
        },
        languages: {
          edges: [
            {
              node: {
                name: 'HTML',
                color: '#e34c26',
                id: 'MDg6TGFuZ3VhZ2U0MTc=',
              },
            },
            {
              node: {
                name: 'JavaScript',
                color: '#f1e05a',
                id: 'MDg6TGFuZ3VhZ2UxNDA=',
              },
            },
            {
              node: {
                name: 'SCSS',
                color: '#c6538c',
                id: 'MDg6TGFuZ3VhZ2U2MDU=',
              },
            },
          ],
        },
      },
    },

    {
      node: {
        id: 'MDEwOlJlcG9zaXRvcnkyOTI5MTk0OTA=',
        name: 'gatsby-portfolio',
        url: 'https://github.com/ankitboghra/gatsby-portfolio',
        homepageUrl: null,
        description: 'Personal portfolio website',
        primaryLanguage: {
          id: 'MDg6TGFuZ3VhZ2UxNDA=',
          color: '#f1e05a',
          name: 'JavaScript',
        },
        languages: {
          edges: [
            {
              node: {
                name: 'CSS',
                color: '#563d7c',
                id: 'MDg6TGFuZ3VhZ2UzMDg=',
              },
            },
            {
              node: {
                name: 'JavaScript',
                color: '#f1e05a',
                id: 'MDg6TGFuZ3VhZ2UxNDA=',
              },
            },
          ],
        },
      },
    },

    {
      node: {
        id: 'MDEwOlJlcG9zaXRvcnkyMzEzNzA2NTU=',
        name: 'expensify-app',
        url: 'https://github.com/ankitboghra/expensify-app',
        homepageUrl: 'https://ankit-expensify.herokuapp.com/',
        description: 'Expense Manager',
        primaryLanguage: {
          id: 'MDg6TGFuZ3VhZ2UxNDA=',
          color: '#f1e05a',
          name: 'JavaScript',
        },
        languages: {
          edges: [
            {
              node: {
                name: 'HTML',
                color: '#e34c26',
                id: 'MDg6TGFuZ3VhZ2U0MTc=',
              },
            },
            {
              node: {
                name: 'SCSS',
                color: '#c6538c',
                id: 'MDg6TGFuZ3VhZ2U2MDU=',
              },
            },
            {
              node: {
                name: 'JavaScript',
                color: '#f1e05a',
                id: 'MDg6TGFuZ3VhZ2UxNDA=',
              },
            },
          ],
        },
      },
    },

    {
      node: {
        id: 'MDEwOlJlcG9zaXRvcnkxOTMwMzY3NzY=',
        name: 'chrome-extensions',
        url: 'https://github.com/ankitboghra/chrome-extensions',
        homepageUrl: null,
        description: 'Chrome extensions made for my convenience.',
        primaryLanguage: {
          id: 'MDg6TGFuZ3VhZ2UxNDA=',
          color: '#f1e05a',
          name: 'JavaScript',
        },
        languages: {
          edges: [
            {
              node: {
                name: 'JavaScript',
                color: '#f1e05a',
                id: 'MDg6TGFuZ3VhZ2UxNDA=',
              },
            },
          ],
        },
      },
    },
  ];
  // const {
  //   github: {
  //     viewer: {
  //       repositories: { edges },
  //     },
  //   },
  // } = useStaticQuery(
  //   graphql`
  //     {
  //       github {
  //         viewer {
  //           repositories(first: 8, orderBy: { field: UPDATED_AT, direction: DESC }) {
  //             edges {
  //               node {
  //                 id
  //                 name
  //                 url
  //                 homepageUrl
  //                 description
  //                 primaryLanguage {
  //                   id
  //                   color
  //                   name
  //                 }
  //                 languages(first: 3, orderBy: { field: SIZE, direction: ASC }) {
  //                   edges {
  //                     node {
  //                       name
  //                       color
  //                       id
  //                     }
  //                   }
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   `
  // );
  return (
    <Wrapper as={Container} id="projects">
      <h2>Projects</h2>
      <Grid>
        {edges.map(({ node }) => (
          <Item key={node.id} as="a" href={node.url} target="_blank" rel="noopener noreferrer" theme={theme}>
            <Card theme={theme}>
              <Content>
                <h4>{node.name}</h4>
                <p>{node.description}</p>
              </Content>
              <Languages>
                {node.languages.edges.map(({ node: languageNode }) => (
                  <Language key={languageNode.id}>{languageNode.name}</Language>
                ))}
              </Languages>
            </Card>
          </Item>
        ))}
      </Grid>
    </Wrapper>
  );
};
